import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { IResetPassword, IUser, IUserAccessRes, IUserRes } from 'src/app/interfaces/user.interface';
import { IView } from 'src/app/interfaces/view.interface';
import { UserDataService } from 'src/app/services/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<IUser>;
  public currentViewsSubject: BehaviorSubject<IView>;
  public currentUser: Observable<IUser>;
  public currentViews: Observable<IView>;
  API_URL = environment.apiUrlLogin;
  API_URL_NO_PREFIX = environment.apiNoPrefix;
  route = '/users';

  constructor(private http: HttpClient,
    private userDataService: UserDataService) {
    this.currentUserSubject = new BehaviorSubject<IUser>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentViewsSubject = new BehaviorSubject<IView>(
      JSON.parse(localStorage.getItem('views'))
    );
    this.currentViews = this.currentViewsSubject.asObservable();
  }

  public get currentUserValue(): IUser {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string): Observable<IUserAccessRes> {
    return this.http.post<IUserAccessRes>(`${this.API_URL}/auth/login`, {
      email,
      password
    }).pipe (map ((data: IUserAccessRes) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      data.data.user.token = data.data.accessToken.token;
      this.userDataService.currentUserValue = data.data.user;
      this.userDataService.userViewsValue = data.data.views;
      localStorage.setItem('currentUser', JSON.stringify(data.data.user));
      localStorage.setItem('views', JSON.stringify(data.data.views));
      localStorage.setItem('token', JSON.stringify(data.data.accessToken.token));
      this.currentUserSubject.next(data.data.user);
      return data;
    }));
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.get<IUserAccessRes>(`${this.API_URL}${this.route}/forgot-password/${email}?email=${email}`, {
    });
  }

  resetPassword(data: IResetPassword, email: string, signature: string): Observable<IUserRes> {
    const params = new HttpParams().append('signature', signature);
    return this.http.post<IUserRes>(`${this.API_URL_NO_PREFIX}/reset-password/${email}`,
    data, { params });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('views');
    localStorage.clear()
    this.userDataService.currentUserValue = null;
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
