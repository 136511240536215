import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InDateFilter } from 'src/app/interfaces/filter/data-filter.interface';
import { FilterDataService } from 'src/app/services/data/filter-data.service.service';
import { FilterDateService } from 'src/app/services/data/filter-date.service';
import { AlertService } from 'src/app/services/utilities/alert.service';
import { FormValidatorService } from 'src/app/services/validators/form-validator.service';
import { WhiteSpaceValidator } from 'src/app/services/validators/no-whitespace-validator.service';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.sass']
})
export class DateFilterComponent implements OnInit, OnDestroy {
  ToolTipText: string;
  dialogTitle = 'Selecciona un rango de fecha';
  rangeForm: FormGroup;

  // Whitespace validation
  wsValidation = { type: 'noWhiteSpace', message: 'No se permite ingresar solo espacios en blanco' };

  // Validation messages
  validationMessages = {
    date: [{ type: 'required', message: 'Debes seleccionar una fecha' }, this.wsValidation]
  };

  dateRangeControl: FormControl = new FormControl('');
  dateFilter: InDateFilter;
  dateFilter2: InDateFilter;

  constructor(
    private dialogRef: MatDialogRef<DateFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private formService: FormValidatorService,
    private alertService: AlertService,
    private datepipe: DatePipe,
    private filterService: FilterDateService,
    private filterDataService: FilterDataService
  ) {
    if(data.flag){
      this.ToolTipText = 'El filtro se realizara conforme a la fecha de corte de nomina';
    }
    this.filterDataService.setDateFilter({} as InDateFilter);
    this.filterService.setDateFilter({} as InDateFilter);
    this.dateFilter = this.filterService.dateFilterValue;
    this.rangeForm = this.createRangeForm();
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.closeDialog();
  }

  createRangeForm(): FormGroup {
    return this.fb.group({
      start: [{
        value: (this.dateFilter.start_complete ? this.dateFilter.start_complete : ''),
        disabled: false
      }, [Validators.required, WhiteSpaceValidator.noWhiteSpace]],
      end: [{
        value: (this.dateFilter.end_complete ? this.dateFilter.end_complete : ''),
        disabled: false
      }, [Validators.required, WhiteSpaceValidator.noWhiteSpace]]
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.rangeForm.valid) {
      const data = this.rangeForm.value;
      this.dateFilter = {
        start: this.datepipe.transform(data.start, 'yyyy-MM-dd'),
        start_complete: data.start,
        end: this.datepipe.transform(data.end, 'yyyy-MM-dd'),
        end_complete: data.end
      };
      //console.log('dateFilter', this.dateFilter);
      this.filterService.setDateFilter(this.dateFilter);
      this.filterDataService.setSelectedFilters(this.dateFilter);
      this.dialogRef.close();
    } else {
      this.alertService.warningMessage('Datos incorrectos', 'Revisa la fecha ingresada');
    }
  }

}
