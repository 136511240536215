import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InDateFilter } from 'src/app/interfaces/filter/data-filter.interface';
import { ITitle } from 'src/app/interfaces/title.interface';
import { FilterDateService } from 'src/app/services/data/filter-date.service';
import { ViewTitleService } from 'src/app/services/utilities/view-title.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.sass']
})
export class ContentLayoutComponent implements OnInit {
  
  breadscrums = [
    {
      title: '',
      items: [''],
      active: ''
    }
  ];
  titleSubscription: Subscription;
  title: ITitle;

  dataSubscription: Subscription;
  dateFilter: InDateFilter;

  constructor(
    private titleService: ViewTitleService,
    private filterDataService: FilterDateService
  ) {
    this.titleSubscription = this.titleService.title.subscribe((data) => {
      this.title = data;
    });

    this.dataSubscription = this.filterDataService.dateFilter.subscribe((data) => {
      this.dateFilter = this.filterDataService.dateFilterValue;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void  {
    this.titleSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
    this.filterDataService.setDateFilter({} as InDateFilter);
  }
}

