import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUser } from '../interfaces/user.interface';
import { IFolder } from '../interfaces/view.interface';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  // USER DATA
  private currentUserSubject: BehaviorSubject<IUser>;
  public currentUser: Observable<IUser>;

  // USER VIEWS
  private userViewsSubject: BehaviorSubject<IFolder[]>;
  public userViews: Observable<IFolder[]>;

  constructor() {
    this.loadData();
  }

  loadData(): void {
    // USER DATA
    this.currentUserSubject = new BehaviorSubject<IUser>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    // USER VIEWS
    this.userViewsSubject = new BehaviorSubject<IFolder[]>(
      JSON.parse(localStorage.getItem('views'))
    );
    this.userViews = this.userViewsSubject.asObservable();
  }

  // USER DATA
  public get currentUserValue(): IUser {
    return this.currentUserSubject.value;
  }

  public set currentUserValue(data: IUser) {
    localStorage.setItem('currentUser', JSON.stringify(data));
    this.currentUserSubject.next(data);
  }

  // USER VIEWS
  public get userViewsValue(): IFolder[] {
    return this.userViewsSubject.value;
  }

  public set userViewsValue(data: IFolder[]) {
    localStorage.setItem('views', JSON.stringify(data));
    this.userViewsSubject.next(data);
  }
}
